<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>

  <div>
    <div class="container">
      <title-cop  :title="(isStreet ? '街道' : '社区' ) + '场所情况'"/>
      <div class="user_status"><total-number :resCount="resCount" :countList="countList" :countName="'场所总数'" :unit="'个'" /></div>
      <div class="split_line"></div>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '街道' : '社区' ) + '各个场所类型数据统计'"/>
      <div class="peple_change"><width-bar v-if="widthBarShow" ref="widthBar" :labelName="valueName" :labelCount="valueList" :dataName="'场所类型统计'" /></div>
    </div>

    <div class="container" v-if="nameList.length > 0">
      <title-cop  :title="(isStreet ? '街道' : '社区' ) + '各社区场所统计'"/>
      <div class="user_age"><stack-bar v-if="stackBarShow" ref="stackBar" :labelName="nameList" :dataList="stackList"/></div>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '街道' : '社区' ) + '场所总数变化情况'"/>
      <div class="line"><line-cop :labelName="labelName" :countList="lineList" /></div>
    </div>
  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
import stackBar from './components/stackBar.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    orgChange,
    SexProgress,
    widthBar,
    UserType,
    lineCop,
    stackBar
  },
  data () {
    return {
      orgId: 0,
      isStreet: true,
      widthBarShow: false,
      stackBarShow: false,
      orgList: [],
      resCount: 0,
      countList: [],
      valueName: [],
      valueList: [],
      labelName: [],
      lineList: [],
      nameList: [],
      stackList: []

    }
  },
  created () {
    this.init()
  },
  methods: {
    orgChange (id, isStreet) {
      this.orgId = id
      this.isStreet = isStreet
      this.init()
    },
    init () {
      this.getTypeList()
    },
    getTypeList () {
      this.stackBarShow = false
      this.widthBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/placeCountStreet'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.data.categoryCount) {
            this.resCount = data.data.categoryCount.total
            let per1, per2, per3
            let category = data.data.categoryCount
            per1 = category.individual ? parseInt((category.individual / category.total) * 100) : 0
            per2 = category.enterprise ? parseInt((category.enterprise / category.total) * 100) : 0
            per3 = category.place == 0 ? 0 : 100 - per1 - per2
            this.countList = [
              {name: '个体工商户', value: category.individual, percent: per1 + '%'},
              {name: '企业', value: category.enterprise, percent: per2 + '%'},
              {name: '场所', value: category.place, percent: per3 + '%'}
            ]
          }
          this.valueName = data.data.placeCount.map(item => item.labelName)
          this.valueList = data.data.placeCount.map(item => item.labelCount)
          this.labelName = data.data.placeCountChart.map(item => item.dates)
          this.lineList = [{name: '个体工商户', value: []}, {name: '企业', value: []}, {name: '场所', value: []}]
          this.lineList[0].value = data.data.placeCountChart.map(item => item.self)
          this.lineList[1].value = data.data.placeCountChart.map(item => item.enterprise)
          this.lineList[2].value = data.data.placeCountChart.map(item => item.place)
          this.nameList = data.data.typeCount ? data.data.typeCount.map(item => item.community).reverse() : []
          this.stackList = [{name: '个体工商户', value: []}, {name: '企业', value: []}, {name: '场所', value: []}]
          this.stackList[0].value = data.data.typeCount ? data.data.typeCount.map(item => item.self).reverse() : []
          this.stackList[1].value = data.data.typeCount ? data.data.typeCount.map(item => item.enterprise).reverse() : []
          this.stackList[2].value = data.data.typeCount ? data.data.typeCount.map(item => item.place).reverse() : []
          this.stackBarShow = true
          this.widthBarShow = true
          this.$nextTick(() => {
            this.$refs.widthBar.initChartBar()
            if (this.nameList.length > 0) {
              this.$refs.stackBar.initChartBar()
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  height: 400px;
}
.line {
  height: 550px;
}
</style>
